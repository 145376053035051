<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import { required } from "vuelidate/lib/validators";
import store from "@/state/store";


export default {
  beforeRouteEnter(to, from, next) {
    const userType = store.getters["auth/getUser"].user_type;
    const permissions = store.getters["auth/permissions"];
    if (
      (userType === 1 || userType === 3) &&
      permissions.includes("MANAGE_EMPLOYEE")
    ) {
      next();
    } else {
      alert("You are not allowed to access this page. You will be redirected.");
      next("/");
    }
  },
  page: {
    title: "Manage Employee",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
  },
  async mounted() {
    this.employeeId = this.$route.params.employeeID;
    await this.fetchEmployee();
    this.getStates();
    this.getBanks();
    this.getJobRoles();
    this.getLocations();
    this.getPensionProviders();
    this.getLocalGovernmentAreas();
    this.getSectors();
    this.getEmployees();
  },

  /*: null,

  : null,
  emp_spouse_name: null,
  emp_spouse_phone_no: null,
  emp_next_of_kin_name: null,
  emp_next_of_kin_address: null,
  emp_next_of_kin_phone_no: null,
  emp_ailments: null,
  emp_blood_group: null,
  emp_genotype: null,
  emp_emergency_name: null,
  emp_emergency_contact: null,
  emp_hire_date: null,
  emp_contract_end_date: null,
  */
  validations: {
    emp_first_name: { required },
    emp_location: { required },
    emp_last_name: { required },
    emp_phone_no: { required },
    emp_state_id: { required },
    emp_lga_id: { required },

    emp_bank_id: { required },
    emp_account_no: { required },
    emp_qualification: { required },
    gender: { required },
    religion: { required },
    job_role: { required },
    //account_number: { required },
  },
  methods: {
    toggleSelected(val) {
      //let {text, value } = val;
      console.log(val);
    },
    positionLabel({ text }) {
      return `${text}`;
    },
    bankLabel({ text }) {
      return `${text}`;
    },
    locationLabel({ text }) {
      return `${text}`;
    },
    pensionLabel({ text }) {
      return `${text}`;
    },
    stateOfOriginLabel({ text }) {
      return `${text}`;
    },
    lGALabel({ text }) {
      return `${text}`;
    },
    async fetchEmployee() {
      let employeeID = this.$route.params.employeeID;
      const url = `${this.ROUTES.employee}/get-employee/${employeeID}`;
      this.apiGet(url, "Get Employee Error").then((res) => {
        const { data } = res;
        //console.log({ data });
        if (data) {
          //console.log('State ID: '+ data.emp_state_id);
          this.emp_first_name = data.emp_first_name;
          this.emp_last_name = data.emp_last_name;
          this.emp_other_name = data.emp_other_name;
          this.emp_qualification = data.emp_qualification;
          this.emp_location = data.location?.location_name;
          this.location_id = data.location?.location_id;
          this.emp_account_no = data.emp_account_no;
          this.emp_phone_no = data.emp_phone_no;
          this.emp_bank_id_val = data.emp_bank_id;
          this.emp_state_id = data.emp_state_id;
          this.emp_lga_id = data.emp_lga_id;
          this.emp_marital_status = data.emp_marital_status;
          this.emp_spouse_name = data.emp_spouse_name;
          this.emp_spouse_phone_no = data.emp_spouse_phone_no;
          this.emp_next_of_kin_name = data.emp_next_of_kin_name;
          this.emp_next_of_kin_address = data.emp_next_of_kin_address;
          this.emp_next_of_kin_phone_no = data.emp_next_of_kin_phone_no;
          this.emp_ailments = data.emp_ailments;
          this.emp_blood_group = data.emp_blood_group;
          this.emp_genotype = data.emp_genotype;
          this.emp_emergency_name = data.emp_emergency_name;
          this.emp_emergency_contact = data.emp_emergency_contact;
          this.job_role_id = data.emp_job_role_id;
          this.gender = data.emp_sex;
          this.emp_t7 = data.emp_unique_id;
          this.emp_account_status = data.emp_status;
          this.emp_bvn = data.emp_bvn;
          this.religion = data.emp_religion;
          this.emp_pension = data.emp_pension;
          this.emp_pension_no = data.emp_pension_no;
          this.pension_provider_id = data.emp_pension_id;
          this.emp_paye = data.emp_paye_no;
          this.emp_passport = data.emp_passport;
          this.emp_sector = data.sector?.department_id;
          this.emp_sector_text = data.sector?.department_name;
          this.emp_d4 = data.operationUnit?.ou_name;
          this.emp_d5 = data.reportingEntity?.re_name;
          this.emp_d6 = data.functionalArea?.fa_name;
          this.emp_d7 = data.emp_d7;
          this.emp_contact_address = data.emp_contact_address;
          this.emp_relocatable = data.emp_relocatable || 0;
          switch (parseInt(data.emp_religion)) {
            case 1:
              this.religion_text = "Christianity";
              break;
            case 2:
              this.religion_text = "Islam";
              break;
            case 3:
              this.religion_text = "Hinduism";
              break;
            case 4:
              this.religion_text = "Buddhism";
              break;
          }
          this.emp_personal_email = data.emp_personal_email;
          this.emp_office_email = data.emp_office_email;
          this.bank_text = data.bank?.bank_name;
          this.state_text = data.state?.s_name;
          this.pension_provider_text = data.pension?.provider_name;
          this.emp_nhf = data.emp_nhf;
          this.job_role_text = data.jobrole?.job_role;
          this.lga_text = data.lga?.lg_name;
          this.birth_date = new Date(data.emp_dob).toISOString().slice(0, 10);
          this.emp_contract_end_date = new Date(data.emp_contract_end_date)
            .toISOString()
            .slice(0, 10);
          this.emp_hire_date = new Date(data.emp_employment_date)
            .toISOString()
            .slice(0, 10);
          this.emp_nin = data.emp_nin;
        }
      });
    },
    getSectors() {
      this.apiGet(this.ROUTES.department, "Get Job Roles Error").then((res) => {
        const { data } = res;
        //console.log({data});
        data.departments.forEach(async (datum) => {
          const dat = {
            value: datum.department_id,
            text: `${datum.d_t3_code} - ${datum.department_name}`,
          };
          if (parseInt(datum.department_id) === parseInt(this.emp_sector)) {
            const val = {
              value: datum.department_id,
              text: `${datum.d_t3_code} - ${datum.department_name}`,
            };
            this.sector.push(val);
          }
          this.sectors.push(dat);
        });
      });
    },
    getJobRoles() {
      this.apiGet(this.ROUTES.jobRole, "Get Job Roles Error").then((res) => {
        const { data } = res;
        //console.log({data});
        data.forEach(async (datum) => {
          const dat = {
            value: datum.job_role_id,
            text: datum.job_role,
          };
          if (parseInt(datum.job_role_id) === parseInt(this.job_role_id)) {
            const val = {
              value: datum.job_role_id,
              text: datum.job_role,
            };
            this.job_role.push(val);
          }
          this.job_roles.push(dat);
        });
      });
    },
    getPensionProviders() {
      this.apiGet(
        this.ROUTES.pensionProvider,
        "Get Pension providers Error"
      ).then((res) => {
        const { data } = res;
        //console.log(data);
        data.forEach(async (datum) => {
          const dat = {
            value: datum.pension_provider_id,
            text: datum.provider_name,
          };
          if (
            parseInt(datum.pension_provider_id) ===
            parseInt(this.pension_provider_id)
          ) {
            const val = {
              value: datum.pension_provider_id,
              text: datum.provider_name,
            };
            this.pensionProvider.push(val);
          }
          this.pensionProviders.push(dat);
        });

        //this.jrs = data;
      });
    },
    getBanks() {
      const url = `${this.ROUTES.bank}`;
      this.apiGet(url).then((res) => {
        const { data } = res;
        this.banks = [{ value: null, text: "Please select from the list" }];
        data.forEach(async (datum) => {
          const dat = {
            value: datum.bank_id,
            text: datum.bank_name,
          };
          if (parseInt(datum.bank_id) === parseInt(this.emp_bank_id_val)) {
            const val = {
              value: datum.bank_id,
              text: datum.bank_name,
            };
            this.emp_bank_id.push(val);
          }
          this.banks.push(dat);
        });
      });
    },
    getLocations() {
      const url = `${this.ROUTES.location}`;
      this.apiGet(url).then((res) => {
        const { data } = res;
        this.locations = [{ value: null, text: "Please select location" }];
        data.forEach(async (datum) => {
          const dat = {
            value: datum.location_id,
            text: datum.location_name,
          };
          if (parseInt(datum.location_id) === parseInt(this.location_id)) {
            const val = {
              value: datum.location_id,
              text: datum.location_name,
            };
            this.location.push(val);
          }
          this.locations.push(dat);
        });
      });
    },
    getEmployees() {
      this.apiGet(this.ROUTES.employee, "Get Employees Error").then((res) => {
        const { data } = res;
        data.forEach((employee) => {
          const emp = {
            value: employee.emp_id,
            text: `${employee.emp_unique_id} ${employee.emp_first_name} ${employee.emp_last_name}`,
          };
          this.employees.push(emp);
        });
      });
    },
    retrieveEmployee() {
      const employeedId = this.selectedEmployee.value;

      //if(employeedId !== null || employeedId !== ''){
      this.$route.params.employeeID = employeedId;
      this.fetchEmployee();
      /*}else{
        alert("Select an employee");
      }*/
    },
    getStates() {
      const url = `${this.ROUTES.state}`;
      this.apiGet(url).then((res) => {
        const { data } = res;
        this.states = [{ value: null, text: "Please select a State" }];
        data.forEach(async (datum) => {
          const dat = {
            value: datum.s_id,
            text: datum.s_name,
          };
          if (parseInt(datum.s_id) === parseInt(this.emp_state_id)) {
            const val = {
              value: datum.s_id,
              text: datum.s_name,
            };
            this.state.push(val);
          }
          this.states.push(dat);
        });
      });
    },
    getLocalGovernmentAreas() {
      const url = `${this.ROUTES.localGovernment}`;
      this.apiGet(url).then((res) => {
        const { data } = res;
        //console.log(data);
        this.lgas = [{ value: null, text: "Please select LGA" }];
        data.forEach((datum) => {
          const dat = {
            value: datum.lg_id,
            text: datum.lg_name,
          };
          if (parseInt(datum.lg_id) === parseInt(this.emp_lga_id)) {
            const val = {
              value: datum.lg_id,
              text: datum.lg_name,
            };
            this.lga.push(val);
          }
          this.lgas.push(dat);
        });
      });
    },
    getLocalGovernmentAreasByStateId() {
      let stateId = this.emp_state_id.value;
      const url = `${this.ROUTES.localGovernment}/${stateId}`;
      this.apiGet(url).then((res) => {
        const { data } = res;
        //console.log(data);
        this.lgas = [{ value: null, text: "Please select LGA" }];
        data.forEach((datum) => {
          const dat = {
            value: datum.lg_id,
            text: datum.lg_name,
          };
          if (parseInt(datum.lg_id) === parseInt(this.emp_lga_id)) {
            const val = {
              value: datum.lg_id,
              text: datum.lg_name,
            };
            this.lga.push(val);
          }
          this.lgas.push(dat);
        });
      });
    },


    async suspendEmployee() {
      this.submitted = true;
      let employeeID = this.$route.params.employeeID;
      const url = `${this.ROUTES.employee}/suspend-employee/${employeeID}`;
      const data = {
        emp_suspension_reason: this.emp_suspension_reason,
      };
      this.apiPatch(url, data, "Suspend Employee Error").then();
      this.apiResponseHandler("Process Complete", "Employee Suspended");
      this.$refs["deactivate-employee"].hide();
      this.fetchEmployee();
      this.submitted = false;
      this.fetchEmployee();
    },
    async unsuspendEmployee() {
      this.submitted = true;
      let employeeID = this.$route.params.employeeID;
      const url = `${this.ROUTES.employee}/unsuspend-employee/${employeeID}`;
      this.apiPatch(url, "Unsuspend Employee Error").then();
      this.apiResponseHandler("Process Complete", "Employee Suspended");
      this.$refs["deactivate-employee"].hide();
      this.fetchEmployee();
      this.submitted = false;
      this.fetchEmployee();
    },
    swapRelocatableStatus(){
      let employeeID = this.$route.params.employeeID;
      let status = this.emp_relocatable === 0 ? 1 : 0;
      const data = {
        status,
        empId:employeeID
      };
      let url = `${this.ROUTES.employee}/swap-relocatable-status`;
      this.apiPost(url, data, "Update relocatable Error").then(
        (res) => {
          this.apiResponseHandler(`${res.data}`, "Status updated!");
          location.reload();
        }
      );
    },
    resetForm() {
      this.emp_suspension_reason = null;
    },
  },
  data() {
    return {
      title: "Manage Employee",
      items: [
        {
          text: "IHUMANE",
        },
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Manage Employee",
          active: true,
        },
      ],
      submitted: false,
      emp_first_name: null,
      emp_location: null,
      emp_last_name: null,
      emp_other_name: null,
      emp_qualification: null,
      emp_phone_no: null,
      emp_account_no: null,
      emp_bank_id: [],
      emp_bank_id_val: null,
      emp_state_id_val: null,
      emp_relocatable: 0,

      state: [],
      states: [],
      state_id: null,

      job_role: [],
      job_roles: [],

      emp_d4:null,
      emp_d5:null,
      emp_d6:null,
      emp_d7:null,

      job_role_id: null,
      bank_text: null,
      lga_text: null,
      state_text: null,
      job_role_text: null,
      emp_lga_id: null,
      emp_marital_status: null,
      emp_spouse_name: null,
      emp_spouse_phone_no: null,
      emp_next_of_kin_name: null,
      emp_next_of_kin_address: null,
      emp_next_of_kin_phone_no: null,
      emp_ailments: null,
      emp_blood_group: null,
      emp_genotype: null,
      emp_emergency_name: null,
      emp_emergency_contact: null,
      emp_bvn: null,
      emp_suspension_reason: null,
      emp_office_email: null,
      emp_personal_email: null,
      emp_hire_date: null,
      emp_contract_end_date: null,
      emp_grade_level: null,
      emp_passport: null,
      emp_contact_address: null,
      emp_t7: null,
      emp_account_status: null,
      locations: [],
      location: [],
      location_id: null,

      maritalStatus: [
        { value: null, text: "select marital Status" },
        { value: 'Married', text: "Married" },
        { value: 'Not Married', text: "Not Married" },
      ],
      gender_options: [
        { value: null, text: "Please select gender" },
        { value: "1", text: "Male" },
        { value: "2", text: "Female" },
      ],
      gender: null,
      religion_options: [
        { value: null, text: "Please select religion" },
        { value: "1", text: "Christianity" },
        { value: "2", text: "Islam" },
        { value: "3", text: "Hinduism" },
        { value: "4", text: "Buddhism" },
      ],
      pension_options: [
        { value: null, text: "Pensionable?" },
        { value: "1", text: "Yes" },
        { value: "2", text: "No" },
      ],
      religion: null,
      religion_text: null,
      emp_pension: null,
      emp_pension_no: null,
      emp_paye: null,
      bank: null,
      banks: [],
      emp_sector: null,
      emp_sector_text: null,
      sectors: [],
      sector: [],
      employees: [],
      selectedEmployee: [],

      pensionProviders: [],
      pensionProvider: [],
      pension_provider_id: null,
      lgas: [],
      jrs: [],
      lga: [],
      stateId: null,
      birth_date: null,
      selectedStateId: null,
      selectedPensionProvider: null,
      emp_nhf: null,
      pension_provider_text: null,
      emp_nin: null,
      employeeId: null,
    };
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <scale-loader v-if="apiBusy" />
    <div v-else class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-3">
                <div
                  class="d-flex"
                  style="cursor: pointer"
                  @click="
                    $router.push({
                      name: 'employee-documents',
                      params: { employeeID: $route.params.employeeID },
                    })
                  "
                >
                  <div class="avatar-sm me-3 mr-1">
                    <span
                      class="avatar-title bg-light rounded-circle text-primary font-size-24"
                    >
                      <i class="ri-upload-cloud-2-fill"></i>
                    </span>
                  </div>
                  <div
                    class="flex-1 align-self-center overflow-hidden ml-2 mb-0"
                  >
                    <h5 class="mb-0">Upload Documents</h5>
                    <p>Upload employee documents</p>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div
                  class="d-flex mt-4 mt-md-0"
                  style="cursor: pointer"
                  @click="
                    $router.push({
                      name: 'employee-work-experience',
                      params: { employeeID: $route.params.employeeID },
                    })
                  "
                >
                  <div class="avatar-sm me-3">
                    <span
                      class="avatar-title bg-light rounded-circle text-primary font-size-24"
                    >
                      <i class="ri-heart-add-fill"></i>
                    </span>
                  </div>
                  <div
                    class="flex-1 align-self-center overflow-hidden ml-2 mb-0"
                  >
                    <h5 class="mb-0">Work Experience</h5>
                    <p class="text-muted mb-0">
                      Mange employee work experience log.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div
                  class="d-flex mt-4 mt-md-0"
                  style="cursor: pointer"
                  @click="
                    $router.push({
                      name: 'employee-education',
                      params: { employeeID: $route.params.employeeID },
                    })
                  "
                >
                  <div class="avatar-sm me-3">
                    <span
                      class="avatar-title bg-light rounded-circle text-primary font-size-24"
                    >
                      <i class="ri-book-2-fill"></i>
                    </span>
                  </div>
                  <div
                    class="flex-1 align-self-center overflow-hidden ml-2 mb-0"
                  >
                    <h5 class="mb-0">Education</h5>
                    <p class="text-muted mb-0">
                      Employee education background log
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="d-flex mt-4 mt-md-0">
                  <div
                    class="flex-1 align-self-center overflow-hidden ml-2 mb-0"
                  >
                    <h5 class="mb-0">Retrieve</h5>
                    <form
                      v-on:submit.prevent="retrieveEmployee"
                      class="form-inline"
                    >
                      <div class="form-group mb-2">
                        <label>Search Employee</label>
                        <multiselect
                          v-model="selectedEmployee"
                          :options="employees"
                          :custom-label="stateOfOriginLabel"
                        ></multiselect>
                      </div>
                      <button type="submit" class="btn btn-primary mt-2 ml-2">
                        <i class="mdi mdi-timer-sand-empty"></i> Retrieve
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-3 col-3">
                <div class="tab-content" id="v-pills-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="product-1"
                    role="tabpanel"
                  >
                    <div class="product-img">
                      <img
                        :src="emp_passport"
                        height="64"
                        width="64"
                        alt="img-1"
                        class="img-fluid mx-auto d-block"
                        data-zoom="assets/images/product/img-1.png"
                      />
                    </div>
                  </div>
                </div>
                <div class="row text-center mt-2">
                  <div class="col-sm-6">
                    <div class="d-grid">
                      <button
                        type="button"
                        v-if="parseInt(emp_account_status) !== 0"
                        @click="$refs['deactivate-employee'].show()"
                        class="btn btn-danger waves-effect waves-light mt-2"
                      >
                        <i class="mdi mdi-cancel me-2"></i> Deactivate
                      </button>
                      <button
                        type="button"
                        v-else
                        @click="$refs['activate-employee'].show()"
                        class="btn btn-success waves-effect waves-light mt-2"
                      >
                        <i class="mdi mdi-check me-2"></i> Activate
                      </button>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="d-grid">
                      <button
                        type="button"
                        @click="$router.push({
                        name: 'edit-employee-details',
                        params: { employeeID: employeeId}
                        })"
                        class="btn btn-light waves-effect mt-2 waves-light"
                      >
                        <i class="mdi mdi-pencil me-2"></i> Edit Profile
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-8 ml-2">
                <div class="mt-4 mt-xl-3">
                  <a href="#" class="text-primary">{{ emp_location }}</a>
                  <h5 class="mt-1 mb-3">
                    {{ emp_first_name }} {{ emp_last_name }}
                    {{ emp_other_name }}
                  </h5>
                  <div class="row">
                    <div class="col-md-6">
                      <h5 class="mt-2">
                        <span class="text-success font-size-12 ms-2">
                          <span class="text-muted">Hire Date: </span
                          >{{ emp_hire_date }}</span
                        >
                      </h5>
                    </div>
                    <div class="col-md-6">
                      <h5 class="mt-2">
                        <span class="text-success font-size-12 ms-2">
                          <span class="text-muted">Birth Date: </span
                          >{{ birth_date }}</span
                        >
                      </h5>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <h5 class="mt-2">
                        <span class="text-success font-size-12 ms-2">
                          <span class="text-muted">Office Email: </span
                          >{{ emp_office_email }}</span
                        >
                      </h5>
                    </div>
                    <div class="col-md-6">
                      <h5 class="mt-2">
                        <span class="text-success font-size-12 ms-2">
                          <span class="text-muted">Personal Email: </span
                          >{{ emp_personal_email }}</span
                        >
                      </h5>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <h5 class="mt-2">
                        <span class="text-success font-size-12 ms-2">
                          <span class="text-muted">Mobile No.: </span
                          >{{ emp_phone_no }}</span
                        >
                      </h5>
                    </div>
                    <div class="col-md-6">
                      <h5 class="mt-2">
                        <span class="text-success font-size-12 ms-2">
                          <span class="text-muted">Contact Address: </span
                          >{{ emp_contact_address }}</span
                        >
                      </h5>
                    </div>
                    <div class="col-md-6">
                      <h5 class="mt-2">
                        <span class="text-success font-size-12 ms-2">
                          <span class="text-muted">Qualification: </span
                          >{{ emp_qualification }}</span
                        >
                      </h5>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <h5 class="mt-2">
                        <span class="text-success font-size-12 ms-2">
                          <span class="text-muted">State: </span
                          >{{ state_text }}</span
                        >
                      </h5>
                    </div>
                    <div class="col-md-6">
                      <h5 class="mt-2">
                        <span class="text-success font-size-12 ms-2">
                          <span class="text-muted">LGA: </span
                          >{{ lga_text }}</span
                        >
                      </h5>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <h5 class="mt-2">
                        <span class="text-success font-size-12 ms-2">
                          <span class="text-muted">Marital Status: </span
                          >{{
                            emp_marital_status
                          }}</span
                        >
                      </h5>
                    </div>
                    <div class="col-md-6">
                      <h5 class="mt-2">
                        <span class="text-success font-size-12 ms-2">
                          <span class="text-muted">Ailment: </span
                          >{{ emp_ailments }}</span
                        >
                      </h5>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <h5 class="mt-2">
                        <span class="text-success font-size-12 ms-2">
                          <span class="text-muted">Gender: </span
                          >{{ gender === 1 ? "Male" : "Female" }}</span
                        >
                      </h5>
                    </div>
                    <div class="col-md-6">
                      <h5 class="mt-2">
                        <span class="text-success font-size-12 ms-2">
                          <span class="text-muted">Religion: </span
                          >{{ religion_text }}</span
                        >
                      </h5>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <h5 class="mt-2">
                        <span class="text-success font-size-12 ms-2">
                          <span class="text-muted">T7: </span>{{ emp_t7 }}</span
                        >
                      </h5>
                    </div>
                    <div class="col-md-6">
                      <h5 class="mt-2">
                        <span class="text-success font-size-12 ms-2">
                          <span class="text-muted">Account Status: </span
                          >{{
                            parseInt(emp_account_status) === 1
                              ? "Active"
                              : "Inactive"
                          }}</span
                        >
                      </h5>
                    </div>
                    <div class="col-md-6">
                      <h5 class="mt-2">
                        <span class="text-success font-size-12 ms-2">
                          <span class="text-muted">Job Role: </span
                          >{{ job_role_text }}</span
                        >
                      </h5>
                    </div>
                    <div class="col-md-6">
                      <h5 class="mt-2">
                        <span class="text-success font-size-12 ms-2">
                          <span class="text-muted">Relocatable?: </span
                          >
                          <span v-if="emp_relocatable == 0">No</span>
                          <span v-else>Yes</span>
                        </span
                        >
                      </h5>
                      <button @click.prevent="swapRelocatableStatus" type="button" class="btn btn-primary">Swap <i class="mdi mdi-swap-horizontal"></i> </button>
                    </div>
                    <div class="col-md-6">
                      <h5 class="mt-2">
                        <span class="text-success font-size-12 ms-2">
                          <span class="text-muted">NIN: {{emp_relocatable}}</span
                          >{{ emp_nin }}</span
                        >
                      </h5>
                      <h5 class="mt-2">
                        <span class="text-success font-size-12 ms-2">
                          <span class="text-muted">Sector: </span
                          >{{ emp_sector_text }}</span
                        >
                      </h5>
                    </div>
                  </div>
                  <hr class="my-4" />

                  <div class="row">
                    <div class="col-md-6">
                      <div>
                        <h5 class="font-size-14 text-uppercase mt-3">
                          Health Information
                        </h5>
                        <h5 class="mt-2">
                          <span class="text-success font-size-12 ms-2">
                            <span class="text-muted">Blood Group: </span
                            >{{ emp_blood_group }}</span
                          >
                        </h5>
                        <h5 class="mt-2">
                          <span class="text-success font-size-12 ms-2">
                            <span class="text-muted">Geno-type: </span
                            >{{ emp_genotype }}</span
                          >
                        </h5>
                        <h5 class="mt-2">
                          <span class="text-success font-size-12 ms-2">
                            <span class="text-muted">HMO: </span
                            >{{ emp_genotype }}</span
                          >
                        </h5>
                        <h5 class="mt-2">
                          <span class="text-success font-size-12 ms-2">
                            <span class="text-muted">HMO No.: </span
                            >{{ emp_genotype }}</span
                          >
                        </h5>

                        <h5 class="font-size-14 text-uppercase mt-3">
                          Emergency Contact Details
                        </h5>
                        <h5 class="mt-2">
                          <span class="text-success font-size-12 ms-2">
                            <span class="text-muted">Name: </span
                            >{{ emp_emergency_name }}</span
                          >
                        </h5>
                        <h5 class="mt-2">
                          <span class="text-success font-size-12 ms-2">
                            <span class="text-muted">Contact: </span
                            >{{ emp_emergency_contact }}</span
                          >
                        </h5>

                        <h5 class="font-size-14 text-uppercase mt-3">
                          Contract Details
                        </h5>
                        <h5 class="mt-2">
                          <span class="text-success font-size-12 ms-2">
                            <span class="text-muted">Start Date: </span
                            >{{ emp_hire_date }}</span
                          >
                        </h5>
                        <h5 class="mt-2">
                          <span class="text-success font-size-12 ms-2">
                            <span class="text-muted">End Date: </span
                            >{{ emp_contract_end_date }}</span
                          >
                        </h5>

                        <h5 class="font-size-14 text-uppercase mt-3">
                          Marital Information:
                        </h5>
                        <h5 class="mt-2">
                          <span class="text-success font-size-12 ms-2">
                            <span class="text-muted">Marital Status: </span
                            >{{ emp_marital_status }}</span
                          >
                        </h5>
                        <h5 class="mt-2">
                          <span class="text-success font-size-12 ms-2">
                            <span class="text-muted">Spouse Name: </span
                            >{{ emp_spouse_name }}</span
                          >
                        </h5>
                        <h5 class="mt-2">
                          <span class="text-success font-size-12 ms-2">
                            <span class="text-muted">Contact No.: </span
                            >{{ emp_spouse_phone_no }}</span
                          >
                        </h5>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <h5 class="font-size-14 text-uppercase mt-3">
                        D Codes:
                      </h5>
                      <h5 class="mt-2">
                        <span class="text-success font-size-12 ms-2">
                          <span class="text-muted">D7: </span
                          >{{ emp_d7 }}</span
                        >
                      </h5>
                      <h5 class="mt-2">
                        <span class="text-success font-size-12 ms-2">
                          <span class="text-muted">D4: </span
                          >{{ emp_d4 }}</span
                        >
                      </h5>
                      <h5 class="mt-2">
                        <span class="text-success font-size-12 ms-2">
                          <span class="text-muted">D5: </span
                          >{{ emp_d5 }}</span
                        >
                      </h5>
                      <h5 class="mt-2">
                        <span class="text-success font-size-12 ms-2">
                          <span class="text-muted">D6: </span
                          >{{ emp_d6 }}</span
                        >
                      </h5>
                      <h5 class="font-size-14 text-uppercase mt-3">
                        Next of Kin:
                      </h5>
                      <h5 class="mt-2">
                        <span class="text-success font-size-12 ms-2">
                          <span class="text-muted">Name: </span
                          >{{ emp_next_of_kin_name }}</span
                        >
                      </h5>
                      <h5 class="mt-2">
                        <span class="text-success font-size-12 ms-2">
                          <span class="text-muted">Address: </span
                          >{{ emp_next_of_kin_address }}</span
                        >
                      </h5>
                      <h5 class="mt-2">
                        <span class="text-success font-size-12 ms-2">
                          <span class="text-muted">Phone No.: </span
                          >{{ emp_next_of_kin_phone_no }}</span
                        >
                      </h5>

                      <h5 class="font-size-14 text-uppercase mt-3">
                        Bank Information:
                      </h5>
                      <h5 class="mt-2">
                        <span class="text-success font-size-12 ms-2">
                          <span class="text-muted">Account No.: </span
                          >{{ emp_account_no }}</span
                        >
                      </h5>
                      <h5 class="mt-2">
                        <span class="text-success font-size-12 ms-2">
                          <span class="text-muted">BVN.: </span
                          >{{ emp_bvn }}</span
                        >
                      </h5>
                      <h5 class="mt-2">
                        <span class="text-success font-size-12 ms-2">
                          <span class="text-muted">Bank.: </span
                          >{{ bank_text }}</span
                        >
                      </h5>

                      <h5 class="font-size-14 text-uppercase mt-3">
                        Pension Information:
                      </h5>
                      <h5 class="mt-2">
                        <span class="text-success font-size-12 ms-2">
                          <span class="text-muted">Pension Provider: </span
                          >{{ pension_provider_text }}</span
                        >
                      </h5>
                      <h5 class="mt-2">
                        <span class="text-success font-size-12 ms-2">
                          <span class="text-muted">Pension No.: </span
                          >{{ emp_pension_no }}</span
                        >
                      </h5>
                      <h5 class="mt-2">
                        <span class="text-success font-size-12 ms-2">
                          <span class="text-muted">PAYE No.: </span
                          >{{ emp_paye }}</span
                        >
                      </h5>
                      <h5 class="mt-2">
                        <span class="text-success font-size-12 ms-2">
                          <span class="text-muted">NHF: </span
                          >{{ emp_nhf }}</span
                        >
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      ref="deactivate-employee"
      title="Deactivate Employee"
      hide-footer
      centered
      title-class="font-18"
      @hidden="resetForm"
    >
      <form @submit.prevent="suspendEmployee">
        <div class="form-group">
          <label for="suspensionReason">
            Suspension Reason <span class="text-danger">*</span>
          </label>
          <textarea
            id="suspensionReason"
            type="text"
            v-model="emp_suspension_reason"
            class="form-control"
          />
        </div>

        <b-button
          v-if="!submitting"
          class="btn btn-success btn-block mt-4"
          type="submit"
        >
          Submit
        </b-button>
        <b-button
          v-else
          disabled
          class="btn btn-success btn-block mt-4"
          type="submit"
        >
          Submitting...
        </b-button>
      </form>
    </b-modal>

    <b-modal
      ref="activate-employee"
      title="Activate Employee"
      hide-footer
      centered
      title-class="font-18"
      @hidden="resetForm"
    >
      <form @submit.prevent="unsuspendEmployee">
        <p>
          Are you sure you want to activate this account
          <code
            >({{ emp_first_name }} {{ emp_last_name }}
            {{ emp_other_name }})</code
          >
          ?
        </p>
        <b-button
          v-if="!submitting"
          class="btn btn-success btn-block mt-4"
          type="submit"
        >
          Activate Account
        </b-button>
        <b-button
          v-else
          disabled
          class="btn btn-success btn-block mt-4"
          type="submit"
        >
          Submitting...
        </b-button>
      </form>
    </b-modal>

  </Layout>
</template>
